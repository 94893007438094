<!-- 解决方案详情页模板 -->
<template>
  <div class="mobile-container mobile-solution-detail" v-title="title">
    <div class="header">
      <mobile-header></mobile-header>
    </div>
    <div class="body">
      <div class="banner">
        <img :src="require(`@/assets/image/mobile/photo-${detail.banner}.png`)">
        <div class="banner-content">
          <div class="banner-title">{{ title }}</div>
          <div class="banner-descript">{{ descript }}</div>
        </div>
      </div>
      <div class="body-wrapper">
        <div class="section defect">
          <div class="section-title">
            <span>{{ detail.defect.title }}</span>
          </div>
          <div class="section-list">
            <div class="section-list-item" v-for="(item, index) in detail.defect.descript" :key="index">
              <div class="icon-image">
                <img src="@/assets/image/mobile/close-green.png">
              </div>
              <div class="descript">{{ item }}</div>
            </div>
          </div>
        </div>
        <div class="section solution">
          <div class="section-title">
            <span>{{ detail.solution.title }}</span>
          </div>
          <div class="section-list">
            <div class="section-list-item" v-for="(item, index) in detail.solution.descript" :key="index">
              <div class="descript">{{ item }}</div>
            </div>
          </div>
        </div>
        <div class="section advantage">
          <div class="section-title no-border">
            <span>{{ detail.advantage.title }}</span>
          </div>
          <div class="section-list">
            <div class="section-list-item" v-for="(item, index) in detail.advantage.descript" :key="index">
              <div class="icon-image">
                <img :src="require(`@/assets/image/mobile/icon-${item.icon}.png`)">
              </div>
              <div class="descript">
                <div class="title">{{ item.title }}</div>
                <div>{{ item.descript }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="section partner">
          <div class="section-title no-border">
            <span>{{ detail.partner.title }}</span>
          </div>
          <div class="partner-list">
            <div class="partner-item" v-for="(item, index) in detail.partner.images" :key="index">
              <img :src="require(`@/assets/image/mobile/logo-${item}.png`)">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 返回主页 -->
    <div class="mobile-back" @click="back">
      <img src="@/assets/image/mobile/icon-33.png">
    </div>
  </div>
</template>

<script>
  // 头部
  import MobileHeader from '@/components/mobile/mobile-header/mobile-header'
  import solutionData from './solution-data'

  export default {
    name: 'MobileSolutionDetail',
    data () {
      return {
        title: '',
        descript: '',
        detail: {
          banner: '1',
          defect: {},
          solution: {},
          advantage: {},
          partner: {}
        }
      }
    },
    components: {
      MobileHeader
    },
    methods: {
      // 返回
      back () {
        this.$router.push({
          path: '/mobileSolution'
        })
      },
      // 初始化
      init () {
        let id = this.$route.params.id
        let arr = solutionData.filter(item => item.id === id)
        if (arr.length > 0) {
          this.title = arr[0].title
          this.descript = arr[0].descript
          this.detail = arr[0].detail
        }
      }
    },
    mounted () {
      this.init()
    }
  }
</script>

<style lang="scss">
  @import '@/components/mobile/style/common.scss';

  .mobile-solution-detail {
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
    .banner {
      width: 100%;
      height: 3rem;
      position: relative;
      .banner-content {
        position: absolute;
        left: $distance-2;
        top: 0.7rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: $white-color;
        .banner-title {
          font-size: $font-size-8;
        }
        .banner-descript {
          font-size: $font-size-2;
          margin-top: $distance-1;
        }
      }
    }
    .body-wrapper {
      background-color: $white-color;
      padding: 0.6rem $distance-1 0;
      .section {
        margin-bottom: 0.6rem;
        .section-title {
          // height: 0.88rem;
          // line-height: 0.88rem;
          font-size: $font-size-8;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: $text-color-1;
          text-align: center;
          position: relative;
          &.no-border {
            border: none;
          }
        }
        .section-list {
          padding: 0.4rem $distance-2 0;
          .section-list-item {
            position: relative;
            padding-left: 0.4rem;
            font-size: $font-size-3;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: $text-color-3;
            line-height: 0.46rem;
            margin-bottom: 0.4rem;
            .icon-image {
              width: 0.2rem;
              height: 0.2rem;
              position: absolute;
              left: 0;
              top: 0.13rem;
            }
          }
        }
        &.solution {
          .section-list-item {
            &:before {
              content: '';
              width: 0.12rem;
              height: 0.03rem;
              background: $text-color-3;
              position: absolute;
              left: 0;
              top: 0.22rem;
            }
          }
        }
        &.advantage {
          .section-list {
            padding: 0;
            border-width: 0 1px 1px 1px;
            border-style: solid;
            border-color: $border-color-2;
            margin-top: 0.4rem;
            .section-list-item {
              margin-bottom: 0;
              padding: 0.4rem $distance-2 0.4rem 1.48rem;
              border-top: 1px solid $border-color-2;
              .icon-image {
                width: 0.88rem;
                height: 0.88rem;
                left: 0.3rem;
                top: 0.4rem;
              }
              .descript {
                .title {
                  color: $text-color-1;
                  margin-bottom: 0.14rem;
                }
              }
            }
          }
        }
        &.partner {
          margin: 0;
          .partner-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin-top: 0.4rem;
            .partner-item {
              border: 1px solid $border-color-2;
              margin-bottom: 0.4rem;
              margin-right: 0.26rem;
              border-radius: 0.08rem;
              img {
                display: block;
                width: 3.34rem;
                height: 1.96rem;
              }
              &:nth-of-type(even) {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
</style>
